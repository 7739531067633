import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import {
  CCardHeader,
  CRow,
  CCol,
  CCard,
  CForm,
  CFormInput,
  CFormLabel,
} from "@coreui/react";

const ContactDetailForm = () => {
  const { isEditing } = useParams();
  const user = useSelector((state) => state.auth.user);
  const [userData, setUserData] = useState(null);
  const userId = user?._id;
  const navigate = useNavigate();
  const pressId = localStorage.getItem("draft-press-release-id");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNo: "",
    email: "",
    companyName: "",
    address: {
      countryName: "",
      addressLine: "",
      city: "",
      state: "",
      zipCode: "",
    },
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/auth/get/${userId}`
        );
        setUserData(response.data);
      } catch (err) {
        console.log("Error in getting the user details", err);
      }
    };

    const fetchContactInfo = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/press-release/get-contact-info/${pressId}`
        );
        setUserData(response.data.contactInfo);
      } catch (err) {
        console.log("Error in getting the user details", err);
      }
    };

    if (isEditing) {
      fetchContactInfo();
    } else {
      fetchUserData();
    }
  }, [userId]);

  useEffect(() => {
    if (userData) {
      setFormData({
        firstName: userData.name || "",
        lastName: userData.lastName || "",
        phoneNo: userData.phoneNo || "",
        email: userData.email || "",
        companyName: userData.companyName || "",
        address: {
          countryName: userData.address?.countryName || "",
          addressLine: userData.address?.addressLine || "",
          city: userData.address?.city || "",
          state: userData.address?.state || "",
          zipCode: userData.address?.zipCode || "",
        },
      });
    }
  }, [userData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData };

    if (name.includes("address")) {
      const [addressField, subField] = name.split(".");
      updatedFormData.address[subField] = value;
    } else {
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);
  };

  // New function to handle phone input validation
  const handlePhoneInput = (e) => {
    // Remove any non-numeric characters
    const numericValue = e.target.value.replace(/[^0-9]/g, "");

    // Update the form with only numeric values
    const updatedFormData = { ...formData };
    updatedFormData.phoneNo = numericValue;
    setFormData(updatedFormData);
  };

  const isFormValid = () => {
    return (
      formData.firstName.trim() &&
      formData.lastName.trim() &&
      formData.email.trim() &&
      formData.companyName.trim() &&
      formData.phoneNo.trim() &&
      formData.address.countryName.trim() &&
      formData.address.addressLine.trim() &&
      formData.address.city.trim() &&
      formData.address.state.trim() &&
      formData.address.zipCode.trim()
    );
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/press-release/update-contact-info/${pressId}`,
        formData
      );
      console.log(response);

      if (isEditing === "true") {
        navigate("/myprofile/press-releases/unapproved");
      } else {
        navigate("/myprofile/press-release/select-price");
      }
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };

  return (
    <Container className="">
      <CRow className="justify-content-center ">
        <h3 className="text-start mb-4">Contact Information</h3>
        <CCard style={{ paddingTop: "22px" }}>
          <CCol md={8} lg={12}>
            <CForm onSubmit={handleContinue}>
              <CRow className="gy-3 pt-1 ">
                <CRow className="mb-3 px-3">
                  {" "}
                  <CCol md={6}>
                    <CFormLabel>First Name</CFormLabel>
                    <CFormInput
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </CCol>
                  <CCol md={6}>
                    <CFormLabel>Last Name</CFormLabel>
                    <CFormInput
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                  </CCol>
                </CRow>
                <CRow className="mb-3 px-3">
                  {" "}
                  <CCol md={6}>
                    <CFormLabel>Mobile Number</CFormLabel>
                    <CFormInput
                      type="tel"
                      name="phoneNo"
                      value={formData.phoneNo}
                      onChange={handlePhoneInput}
                      pattern="[0-9]*"
                      inputMode="numeric"
                      required
                    />
                  </CCol>
                  <CCol md={6}>
                    <CFormLabel>Email</CFormLabel>
                    <CFormInput
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </CCol>
                </CRow>{" "}
                <CRow className="mb-3 px-3">
                  {" "}
                  <CCol md={12}>
                    <CFormLabel>Company Name</CFormLabel>
                    <CFormInput
                      type="text"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                      required
                    />
                  </CCol>
                </CRow>{" "}
                <CRow className="mb-3 px-3">
                  {" "}
                  <CCol md={8}>
                    <CFormLabel>Address Line</CFormLabel>
                    <CFormInput
                      type="text"
                      name="address.addressLine"
                      value={formData.address.addressLine}
                      onChange={handleChange}
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormLabel>City</CFormLabel>
                    <CFormInput
                      type="text"
                      name="address.city"
                      value={formData.address.city}
                      onChange={handleChange}
                      required
                    />
                  </CCol>
                </CRow>{" "}
                <CRow className="mb-3 px-3">
                  {" "}
                  <CCol md={4}>
                    <CFormLabel>State</CFormLabel>
                    <CFormInput
                      type="text"
                      name="address.state"
                      value={formData.address.state}
                      onChange={handleChange}
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormLabel>Country</CFormLabel>
                    <CFormInput
                      type="text"
                      name="address.countryName"
                      value={formData.address.countryName}
                      onChange={handleChange}
                      required
                    />
                  </CCol>
                  <CCol md={4}>
                    <CFormLabel>Zip Code</CFormLabel>
                    <CFormInput
                      type="text"
                      name="address.zipCode"
                      value={formData.address.zipCode}
                      onChange={handleChange}
                      required
                    />
                  </CCol>
                </CRow>
                <CCardHeader className="border-bottom-0 p-0">
                  <CCol md={12} className=" p-3">
                    <button className="common-btn" disabled={!isFormValid()}>
                      Continue
                    </button>
                  </CCol>
                </CCardHeader>
              </CRow>
            </CForm>
          </CCol>
        </CCard>
      </CRow>
    </Container>
  );
};

export default ContactDetailForm;
