import React from 'react'

function DashboardBottom() {
  return (
    <div className="bottom-menu">
      <div className="menu-item active" onclick="setActive(this, 'home')">
        <i className="fas fa-home" />
        <p>Home</p>
      </div>
      <div className="menu-item" onclick="setActive(this, 'search')">
        <i className="fas fa-search" />
        <p>Search</p>
      </div>
      <div className="menu-item" onclick="setActive(this, 'notifications')">
        <i className="fas fa-bell" />
        <p>Notifications</p>
      </div>
      <div className="menu-item" onclick="setActive(this, 'profile')">
        <i className="fas fa-user" />
        <p>Profile</p>
      </div>
    </div>
  );
}

export default DashboardBottom