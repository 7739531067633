import React from "react";
import CreatePage from "./views/createPage/CreatePage.jsx";
import ManagePages from "./views/createPage/ManagePage.jsx";
const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const Colors = React.lazy(() => import("./views/theme/colors/Colors"));

const Members = React.lazy(() => import("./views/members/members/Members"));
const UpdateUser= React.lazy(() => import("./views/members/update-user/UpdateUser"));

const PressRelease = React.lazy(() =>
  import("./views/press-release/add-press/AddPress")
);
const ApprovedPost = React.lazy(() =>
  import("./views/press-release/approved-post/ApprovedPost")
);

const UnapprovedPost = React.lazy(() =>
  import("./views/press-release/unapproved-post/UnapprovedPost")
);
const Rejected = React.lazy(() =>
  import("./views/press-release/rejected/Rejected")
);
const Draft = React.lazy(() => import("./views/press-release/draft/Draft"));

const Deleted = React.lazy(() =>
  import("./views/press-release/deleted/Deleted")
);
const Orders = React.lazy(() => import("./views/orders/orders/Orders"));
const Ads = React.lazy(() => import("./views/ads/createAds/CreateAds"));
const ManageAds = React.lazy(() => import("./views/ads/manage-ads/ManageAds"));

const Category = React.lazy(() =>
  import("./views/category/createCategory/CreateCategory")
);
const ManageCategory = React.lazy(() =>
  import("./views/category/manage-category/ManageCategory")
);

const Plans = React.lazy(() => import("./views/plans/createPlas/CreatePlans"));
const ManagePlans = React.lazy(() =>
  import("./views/plans/manage-plans/ManagePlans")
);
const ManagePlanFeatures = React.lazy(() =>
  import("./views/plans/manage-planFeatures/ManagePlanFeatures")
);

const adminRoutes = [
  { path: "/dashboard", exact: true, name: "Dashboard" },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  { path: "/theme", name: "Theme", element: Colors, exact: true },
  { path: "/theme/colors", name: "Colors", element: Colors },
  { path: "/members", name: "Members", element: Members, exact: true },

  { path: "/admin/members", name: "Members", element: Members },
  {
    path: "/members/edit/:id",
    name: "Edit Member",
    element: UpdateUser,
  },

  {
    path: "/press-release",
    name: "Post",
    element: PressRelease,
    exact: true,
  },

  {
    path: "/press-release/add-press",
    name: "Add Press",
    element: PressRelease,
  },

  {
    path: "/press-release/edit/:slug",
    name: "Edit Press",
    element: PressRelease,
  },

  {
    path: "/press-release/approved",
    name: "Approved Press",
    element: ApprovedPost,
  },

  {
    path: "/press-release/unapproved",
    name: "Unapproved Press",
    element: UnapprovedPost,
  },
  {
    path: "/press-release/rejected",
    name: "Rejected Press",
    element: Rejected,
  },
  {
    path: "/press-release/draft",
    name: " Draft Press",
    element: Draft,
  },

  {
    path: "/press-release/deleted",
    name: " delete Press",
    element: Deleted,
  },

  { path: "/orders", name: "Orders", element: Orders, exact: true },
  { path: "/admin/orders", name: "Orders", element: Orders },

  {
    path: "/ads",
    name: "Ads",
    element: Ads,
    exact: true,
  },
  {
    path: "/ads/createAds",
    name: " Create Ads",
    element: Ads,
  },

  {
    path: "/ads/edit/:id",
    name: "Edit Ads",
    element: Ads,
  },

  {
    path: "/ads/manage-ads",
    name: "Manage Ads",
    element: ManageAds,
  },

  {
    path: "/category",
    name: "Category",
    element: Category,
    exact: true,
  },
  {
    path: "/category/createCategory",
    name: " Add Category",
    element: Category,
  },

  {
    path: "/category/edit/:id",
    name: "Edit Category",
    element: Category,
  },

  {
    path: "/category/manage-category",
    name: "Manage Category",
    element: ManageCategory,
  },

  {
    path: "/plans",
    name: "Plans",
    element: Plans,
    exact: true,
  },
  {
    path: "/plans/createPlans",
    name: " Create plans",
    element: Plans,
  },

  {
    path: "/plans/edit/:planName",
    name: "Edit Plans",
    element: Plans,
  },

  {
    path: "/plans/manage-plans",
    name: "Manage Plans",
    element: ManagePlans,
  },
  {
    path: "/plans/manage-planFeatures",
    name: "Manage PlanFeatures",
    element: ManagePlanFeatures,
  },

  {
    path: "/pages",
    name: "Custom Pages",
    element: Plans,
    exact: true,
  },
  {
    path: "/pages/createPage",
    name: "Create Page",
    element: CreatePage,
  },

  {
    path: "/pages/edit/:url",
    name: "Edit Page",
    element: CreatePage,
  },

  {
    path: "/pages/manage-pages",
    name: "Manage Page",
    element: ManagePages,
  },

];

export default adminRoutes;
