import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from "axios";
import { Container } from 'react-bootstrap';

function CustomPage() {
    const {url}=useParams()
    const [page,setPage]=useState();

    useEffect(()=>{
        const fetchCustomPage= async()=>{
            const response= await axios.get(`${process.env.REACT_APP_BASE_URL}/api/admin/customPage/get/${url}`);
setPage(response.data.page);
        }

        if(url){
            fetchCustomPage()
        }
    },[url])

  return (
   <Container >
   <img src={page?.posterImg?.url} width={"100%"} alt={page?.posterImg?.fileName} />
   <h1>{page?.heading}</h1>
  
   <div dangerouslySetInnerHTML={{ __html: page?.content }} />
   </Container>
  )
}

export default CustomPage