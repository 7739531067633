import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer id="footer" className="footer light-background">
      <div className="container footer-top">
        <div className="row gy-4">
          <div className="col-lg-4 col-md-6 footer-about">
            <Link to="/" className="logo d-flex align-items-center">
              <img src="images/press-release-logo-150.png" alt="" />
            </Link>
            <div className="footer-contact pt-3">
              <p>1023, Tower A, The Ithum, A-40, Sector 62</p>
              <p>Noida, Uttar Pradesh - 201301</p>
              <p className="mt-3">
                <Link to="tel:+919810359349">
                  <strong>Phone:</strong> <span>+91.9810359349</span>
                </Link>
              </p>
              <p>
                <Link to="mailto:pr@pressrelease.org">
                  <strong>Email:</strong> <span>pr@pressrelease.org</span>
                </Link>
              </p>
            </div>
            <div className="social-links d-flex mt-4">
              <Link to="https://x.com/PressReleaseMKT" target="_blank">
                <i className="bi bi-twitter-x" />
              </Link>
              <Link
                to="https://www.facebook.com/submitfreepressrelease"
                target="_blank"
              >
                <i className="bi bi-facebook" />
              </Link>
              <Link to="">
                <i className="bi bi-linkedin" target="_blank" />
              </Link>
            </div>
          </div>
          <div className="col-lg-8 col-12 footer-flex">
            <div className="row w-100 justify-content-between">
              <div className="col-lg-2 col-md-6 col-6 footer-links">
                <h4>PR Distribution</h4>
                <ul>
                  <li>
                    <Link to="#">How It Works</Link>
                  </li>
                  <li>
                    <Link to="#">Why Us</Link>
                  </li>
                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                  <li>
                    <Link to="">Distribution</Link>
                  </li>
                  <li>
                    <Link to="/editorial-guidelines">Editorial Guidelines</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-6 footer-links">
                <h4>About</h4>
                <ul>
                  <li>
                    <Link to="/about">About Press Release</Link>
                  </li>
                  <li>
                    <Link to="/client-testimonial">Client Testimonials</Link>
                  </li>
                  <li>
                    <Link to="#">Investor Inquiries</Link>
                  </li>
                  <li>
                    <Link to="#">Career Opportunities</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-6 footer-links">
                <h4>Press Release</h4>
                <ul>
                  <li>
                    <Link to="#">By Industry</Link>
                  </li>
                  <li>
                    <Link to="#">By Country</Link>
                  </li>
                  <li>
                    <Link to="#">All Press Release</Link>
                  </li>
                  <li>
                    <Link to="#">RSS Feeds</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-6 footer-links">
                <h4>Policies</h4>
                <ul>
                  <li>
                    <Link to="#">Terms &amp; Conditions</Link>
                  </li>
                  <li>
                    <Link to="#">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="#">Content Moderation Policies</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-6 footer-links">
                <h4>Help &amp; Support</h4>
                <ul>
                  <li>
                    <Link to="#">Help/Support</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                  <li>
                    <Link to="#">Feedback</Link>
                  </li>
                  <li>
                    <Link to="#">Report Problem</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between container copyright text-center mt-4 footer_flex_direction">
        <p>
          © <span>Copyright</span>{" "}
          <strong className="px-1 sitename">
            <Link to="/">Pressrelease.org.in</Link>
          </strong>
          . <span>All Rights Reserved</span>
        </p>
        <div className="credits">
          Designed by{" "}
          <Link to="https://www.dreamzsop.com" target="_blank">
            DREAMZSOP
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
