import { createBrowserRouter, Navigate } from "react-router-dom";
import React, { lazy } from "react";
import ContactDetailForm from "./component/client/ContactDetailForm";
import UserPost from "./pages/UserPost";
import Profile from "./pages/User/Profile";
import HashTag from "./component/client/HashTag";
import { useSelector } from "react-redux";
import EditorialGuidelines from "./pages/EditorialGuidelines";
import SelectPrice from "./component/client/SelectPrice";
import Dashboard from "./pages/User/Dashboard";
import Testimonials from "./pages/Testimonials";
import CustomPage from "./pages/CustomPage";
const UserProfile = lazy(() => import("./pages/User/UserProfile"));
const ProfilePage = lazy(() => import("./pages/ProfilePage"));
const About = lazy(() => import("./pages/About"));
const Pricing = lazy(() => import("./pages/Pricing"));
const SubmitPressRelease = lazy(() => import("./pages/SubmitPressRelease"));
const Faq = lazy(() => import("./pages/Faq"));
const App = lazy(() => import("./pages/App"));
const Home = lazy(() => import("./pages/Home"));
const SignIn = lazy(() => import("./pages/SingIn"));
const OtpVarification = lazy(() => import("./pages/OtpVarification"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const PressReleasePage = lazy(() =>
  import("./component/client/PressReleasePage")
);
const PressReleaseDetail = lazy(() => import("./pages/PressReleaseDetail"));
const Contact = lazy(() => import("./pages/Contact"));

const ProtectedRoute = () => {
  const isSignin = useSelector((state) => state.auth.isSignin);

  return isSignin ? <Dashboard /> : <Navigate to="/signin" replace />;
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/pr/:url",
        element: <CustomPage />,
      },
      { path: "/", element: <Home /> },
      { path: "/profile/:userName", element: <ProfilePage /> },
      { path: "/signin", element: <SignIn to="signin" /> },
      { path: "/signup", element: <SignIn to="signup" /> },
      { path: "/forget-pass", element: <SignIn to="forget-pass" /> },
      { path: "/otpSent", element: <OtpVarification /> },
      { path: "/reset-pass", element: <ResetPassword /> },
      {
        path: "/:category",
        element: <PressReleasePage />,
      },
      

      { path: "/:category/:slug", element: <PressReleaseDetail /> },
     
      { path: "/about", element: <About /> },
      { path: "/pricing", element: <Pricing /> },
      { path: "/contact", element: <Contact /> },
      { path: "/faq", element: <Faq /> },
      {path:"/client-testimonial",element:<Testimonials />},
      // { path: "/editorial-guidelines", element: <EditorialGuidelines /> },
      {
        path: "/tags/:tag",
        element: <HashTag />,
      },
      {
        path: "/dashboard",
        element: <ProtectedRoute />,
        children: [
          { path: "/dashboard", element: <Profile /> },
          { path: "/dashboard/press-releases/:status", element: <UserPost /> },
          {
            path: "/dashboard/submit-press-release",
            element: <SubmitPressRelease />,
          },
          {
            path: "/dashboard/press-release/contact-details/:isEditing",
            element: <ContactDetailForm />,
          },
          {
            path: "/dashboard/press-release/select-price",
            element: <SelectPrice />,
          },

          {
            path: "/dashboard/submit-press-release/edit/:slug",
            element: <SubmitPressRelease />,
          },
        ],
      },
    ],
  },
]);
