import React from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const DynamicBreadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <Container fluid className="breadCrumb-custom mt-2">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
          Home
        </Breadcrumb.Item>
        {pathnames
          .filter((value) => !value.startsWith("0"))
          .map((value, index) => {
            let formattedValue1 = decodeURIComponent(value);
            let formattedValue = formattedValue1.replace(/-/g, " ");
            if (formattedValue.toLowerCase() === "tags") {
              formattedValue = "Press Release";
              if (pathnames.length === 1) {
                return (
                  <Breadcrumb.Item
                    linkAs={Link}
                    linkProps={{ to: "/" }}
                    key="/"
                  >
                    Home
                  </Breadcrumb.Item>
                );
              }
            }

            if (pathnames.includes("tags")) {
              formattedValue = formattedValue.replace(/\s+/g, "-");
            }

            const to = `/${pathnames.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnames.length - 1;
            const displayValue = capitalizeWords(formattedValue);

            return isLast ? (
              <Breadcrumb.Item active key={to}>
                {displayValue}
              </Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item linkAs={Link} linkProps={{ to }} key={to}>
                {displayValue}
              </Breadcrumb.Item>
            );
          })}
      </Breadcrumb>
    </Container>
  );
};

export default DynamicBreadcrumb;
