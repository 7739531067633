import React from "react";
import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

function Testimonials() {
  return (
    <section id="testimonials" className="testimonials section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 info" data-aos="fade-up" data-aos-delay={100}>
            <h3>Testimonials</h3>
            <p>
              Hear from our satisfied clients about how our press release services
              have helped them achieve their communication goals.
            </p>
          </div>
          <div className="col-lg-7" data-aos="fade-up" data-aos-delay={200}>
            <Swiper
              modules={[Pagination, Autoplay]} 
              spaceBetween={50}
              slidesPerView={1}
              pagination={{ clickable: true }} 
              autoplay={{ delay: 3000, disableOnInteraction: false }} // Auto slide with a 3-second delay
            >
              {/* Testimonial 1 */}
              <SwiperSlide>
                <div className="testimonial-item">
                  <div className="d-flex">
                    <img
                      src="/images/testimonials/testimonials-1.jpg"
                      className="testimonial-img flex-shrink-0"
                      alt="Saul Goodman"
                    />
                    <div>
                      <h3>Saul Goodman</h3>
                      <h4>CEO &amp; Founder</h4>
                      <div className="stars">
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                      </div>
                    </div>
                  </div>
                  <p>
                    <i className="bi bi-quote quote-icon-left" />
                    <span>
                      The service exceeded our expectations. Our press release was
                      well-written, distributed promptly, and reached the right media
                      contacts. Highly recommend!
                    </span>
                    <i className="bi bi-quote quote-icon-right" />
                  </p>
                </div>
              </SwiperSlide>

              {/* Testimonial 2 */}
              <SwiperSlide>
                <div className="testimonial-item">
                  <div className="d-flex">
                    <img
                      src="/images/testimonials/testimonials-2.jpg"
                      className="testimonial-img flex-shrink-0"
                      alt="Sara Wilsson"
                    />
                    <div>
                      <h3>Sara Wilsson</h3>
                      <h4>Designer</h4>
                      <div className="stars">
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                      </div>
                    </div>
                  </div>
                  <p>
                    <i className="bi bi-quote quote-icon-left" />
                    <span>
                      Thanks to this service, our product launch reached a wider
                      audience than we ever anticipated. The media coverage has been
                      phenomenal!
                    </span>
                    <i className="bi bi-quote quote-icon-right" />
                  </p>
                </div>
              </SwiperSlide>

              {/* Testimonial 3 */}
              <SwiperSlide>
                <div className="testimonial-item">
                  <div className="d-flex">
                    <img
                      src="/images/testimonials/testimonials-3.jpg"
                      className="testimonial-img flex-shrink-0"
                      alt="Jena Karlis"
                    />
                    <div>
                      <h3>Jena Karlis</h3>
                      <h4>Store Owner</h4>
                      <div className="stars">
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                      </div>
                    </div>
                  </div>
                  <p>
                    <i className="bi bi-quote quote-icon-left" />
                    <span>
                      The team made the entire press release process seamless, and the
                      results speak for themselves. We gained significant media exposure
                      in our industry.
                    </span>
                    <i className="bi bi-quote quote-icon-right" />
                  </p>
                </div>
              </SwiperSlide>

              {/* Testimonial 4 */}
              <SwiperSlide>
                <div className="testimonial-item">
                  <div className="d-flex">
                    <img
                      src="/images/testimonials/testimonials-4.jpg"
                      className="testimonial-img flex-shrink-0"
                      alt="Matt Brandon"
                    />
                    <div>
                      <h3>Matt Brandon</h3>
                      <h4>Freelancer</h4>
                      <div className="stars">
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                      </div>
                    </div>
                  </div>
                  <p>
                    <i className="bi bi-quote quote-icon-left" />
                    <span>
                      I was impressed with how easy it was to use the platform. Our press
                      release went out to a targeted audience, and the results were
                      outstanding.
                    </span>
                    <i className="bi bi-quote quote-icon-right" />
                  </p>
                </div>
              </SwiperSlide>

              {/* Testimonial 5 */}
              <SwiperSlide>
                <div className="testimonial-item">
                  <div className="d-flex">
                    <img
                      src="/images/testimonials/testimonials-5.jpg"
                      className="testimonial-img flex-shrink-0"
                      alt="John Larson"
                    />
                    <div>
                      <h3>John Larson</h3>
                      <h4>Entrepreneur</h4>
                      <div className="stars">
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                      </div>
                    </div>
                  </div>
                  <p>
                    <i className="bi bi-quote quote-icon-left" />
                    <span>
                      Working with this service helped our company gain valuable media
                      coverage. The press release got significant attention and even
                      resulted in a feature article!
                    </span>
                    <i className="bi bi-quote quote-icon-right" />
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
