import React from "react";
import { Link } from "react-router-dom";
function Profile() {
  return (
    <div className="container right-content">
      <div className="tab-content mt-3" id="myTabContent">
        <div className="tab-pane fade show active" id="home" role="tabpanel">
          <div className="">
            <h4 className="home-page-main-heading">Press Release</h4>
            <div className="row pd_dash">
              <div className="col-6 col-lg-3">
                <Link
                  to="/dashboard/submit-press-release"
                  className="text-decoration-none"
                  style={{ display: "block" }}
                >
                  <div
                    className="div_1"
                    style={{
                      backgroundColor: "#f3dcf7",
                      boxShadow: "0px 0px 9px -4px #b77dab",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="icon"
                      style={{ backgroundColor: "#dc8ee1" }}
                    >
                      <i className="fa-regular fa-user" />
                    </div>
                    <p className="div_number">09</p>
                    <p className="div_name">Submit Press Releases</p>
                  </div>
                </Link>
              </div>

              <div className="col-6 col-lg-3">
                <Link
                  to="/dashboard/press-releases/approved"
                  className="text-decoration-none"
                  style={{ display: "block" }}
                >
                  <div
                    className="div_1"
                    style={{
                      backgroundColor: "#f3dcf7",
                      boxShadow: "0px 0px 9px -4px #b77dab",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="icon"
                      style={{ backgroundColor: "#dc8ee1" }}
                    >
                      <i className="fa-regular fa-user" />
                    </div>
                    <p className="div_number">09</p>
                    <p className="div_name">Live Press Releases</p>
                  </div>
                </Link>
              </div>

              <div className="col-6 col-lg-3">
                <Link
                  to="/dashboard/press-releases/draft"
                  className="text-decoration-none"
                  style={{ display: "block" }}
                >
                  {" "}
                  <div
                    className="div_1"
                    style={{
                      backgroundColor: "#dce2f7",
                      boxShadow: "0px 0px 9px -4px #7b7999",
                    }}
                  >
                    <div
                      className="icon"
                      style={{ backgroundColor: "#7e7baf" }}
                    >
                      <i className="fa-regular fa-user" />
                    </div>
                    <p className="div_number">20</p>

                    <p className="div_name">Drafted Press Releases</p>
                  </div>
                </Link>
              </div>
              <div className="col-6 col-lg-3">
                <Link
                  to="/dashboard/press-releases/unapproved"
                  className="text-decoration-none"
                  style={{ display: "block" }}
                >
                  {" "}
                  <div
                    className="div_1"
                    style={{
                      backgroundColor: "#f7f6dc",
                      boxShadow: "0px 0px 9px -4px #7c7f54",
                    }}
                  >
                    <div
                      className="icon"
                      style={{ backgroundColor: "#b3b28a" }}
                    >
                      <i className="fa-regular fa-user" />
                    </div>
                    <p className="div_number">34</p>
                    <p className="div_name">Waiting for Approval</p>
                  </div>
                </Link>
              </div>
              <div className="col-6 col-lg-3">
                <Link
                  to="/dashboard/press-releases/rejected"
                  className="text-decoration-none"
                  style={{ display: "block" }}
                >
                  {" "}
                  <div
                    className="div_1"
                    style={{
                      backgroundColor: "#cdefdf",
                      boxShadow: "0px 0px 9px -4px #6c9764",
                    }}
                  >
                    <div
                      className="icon"
                      style={{ backgroundColor: "#628b7f" }}
                    >
                      <i className="fa-regular fa-user" />
                    </div>
                    <p className="div_number">17</p>
                    <p className="div_name">Rejected Press Releases</p>
                  </div>
                </Link>
              </div>
              <div className="col-6 col-lg-3">
                <Link
                  to="/dashboard/press-releases/deleted"
                  className="text-decoration-none"
                  style={{ display: "block" }}
                >
                  {" "}
                  <div
                    className="div_1"
                    style={{
                      backgroundColor: "#c0e5f3",
                      boxShadow: "0px 0px 9px -4px #b6c9cd",
                    }}
                  >
                    <div
                      className="icon"
                      style={{ backgroundColor: "#2bb3e7" }}
                    >
                      <i className="fa-regular fa-user" />
                    </div>
                    <p className="div_number">13</p>
                    <p className="div_name">Deleted Press Releases</p>
                  </div>
                </Link>
              </div>
            </div>
            {/* <h4 className="home-page-main-heading">Settings</h4>
            <div className="update-profile">
              <h5 className="update-profile-h5">Update Profile</h5>
              <div className="mb-4">
                <img src="img/boy-90.png" alt="" />
                <button className="change-pic-btn">Change Picture </button>
              </div>
              <div className="profile-form-div">
                <label htmlFor="">Profile Name</label>
                <br />
                <input type="text" name="" id="" />
              </div>
              <div className="profile-form-div">
                <label htmlFor="">Username</label>
                <br />
                <input type="text" name="" id="" />
              </div>
              <h5 className="update-profile-h5-1">About Me</h5>
              <div className="row about-row-profile">
                <div className="col-12 profile-form-info-div">
                  <textarea
                    name=""
                    id=""
                    placeholder="Tell something about yourself in detail....."
                    rows={4}
                    cols={5}
                    defaultValue={""}
                  />
                </div>
                <div className="col-6 profile-form-info-div">
                  <label htmlFor="">Email</label>
                  <br />
                  <input type="email" />
                </div>
                <div className="col-6 profile-form-info-div">
                  <label htmlFor="">Phone</label>
                  <br />
                  <input type="tel" />
                </div>
                <div className="col-6 profile-form-info-div">
                  <label htmlFor="">Date of Birth</label>
                  <br />
                  <input type="date" />
                </div>
                <div className="col-6 profile-form-info-div">
                  <label htmlFor="">Country</label>
                  <br />
                  <input type="text" />
                </div>
                <div className="col-6 profile-form-info-div">
                  <label htmlFor="">Website</label>
                  <br />
                  <input type="text" />
                </div>
              </div>
              <div className="col-6 profile-form-info-div">
                <label htmlFor="">Social Profiles</label>
                <br />
              </div>
            </div> */}
            {/* <h4 className="home-page-main-heading">Billings and Settings</h4> */}
          </div>
        </div>
        {/* <div className="tab-pane fade" id="search" role="tabpanel">
          <h3>Search</h3>
          <p>Search for information here.</p>
        </div> */}
        {/* <div className="tab-pane fade" id="notifications" role="tabpanel">
          <div className="row">
            <div className="container">
              <div className="col-12 notification_div">
                <img src="img/boy1.jpg" alt="" />
                <div className="">
                  <p className="notification_name">
                    Lorem ipsum notified you as a member.
                  </p>
                  <p className="notification_date">2 hours ago</p>
                </div>
              </div>
              <div className="col-12 notification_div">
                <img src="img/boy1.jpg" alt="" />
                <div className="">
                  <p className="notification_name">
                    Lorem ipsum notified you as a member.
                  </p>
                  <p className="notification_date">2 hours ago</p>
                </div>
              </div>
              <div className="col-12 notification_div">
                <img src="img/boy1.jpg" alt="" />
                <div className="">
                  <p className="notification_name">
                    Lorem ipsum notified you as a member.
                  </p>
                  <p className="notification_date">2 hours ago</p>
                </div>
              </div>
              <div className="col-12 notification_div">
                <img src="img/boy1.jpg" alt="" />
                <div className="">
                  <p className="notification_name">
                    Lorem ipsum notified you as a member.
                  </p>
                  <p className="notification_date">2 hours ago</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="tab-pane fade" id="profile" role="tabpanel">
          <h3>Profile</h3>
          <p>Manage your profile settings.</p>
        </div> */}
      </div>
    </div>
  );
}

export default Profile;
