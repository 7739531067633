import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchPressRelease = createAsyncThunk(
  "pressRelease/admin/fetchPressRelease",
  async ({ approvedStatus, page,deleted , limit, search }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/press-release/get`,
        {
          params: { page, limit, search, approvedStatus, deleted },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error fetching Press Release"
      );
    }
  }
);

export const updateApprovedStatus = createAsyncThunk(
  "pressRelease/admin/updateApprovedStatus",
  async ({ id, approvedStatus }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/admin/press-release/update-approved-status`,
        { id, approvedStatus }
      );
      return { id, approvedStatus, message: response.data.message };
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error updating Press Release status"
      );
    }
  }
);


const pressReleaseSlice = createSlice({
  name: "pressRelease",
  initialState: {
    list: [],
    total: 0,
    page: 1,
    totalPages: 1,
    filterPressRelease: "all",
    searchQuery: "",
    status: "idle",
    loading: false,
    error: null,
  },
  reducers: {
    setFilterPressRelease: (state, action) => {
      state.filterPressRelease = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    deletePressReleaseItem: (state, action) => {
      const pressReleaseItem = state.list.find(
        (pressRelease) => pressRelease._id === action.payload
      );
      if (pressReleaseItem) {
        pressReleaseItem.deleted = true;
      }
    },

    updatePressReleaseVisibility: (state, action) => {
      const { id, visibility } = action.payload;
      const pressReleaseItem = state.list.find(
        (pressRelease) => pressRelease._id === id
      );
      if (pressReleaseItem) {
        pressReleaseItem.contentFor = visibility;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPressRelease.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPressRelease.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.list = action.payload.pressRelease?.map((pressRelease) => ({
          ...pressRelease,
          deleted: false,
        }));
        state.total = action.payload.total;
        state.page = action.payload.page;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchPressRelease.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateApprovedStatus.fulfilled, (state, action) => {
        const { id, approvedStatus } = action.payload;
        const pressReleaseItem = state.list.find(
          (pressRelease) => pressRelease._id === id
        );
        if (pressReleaseItem) {
          pressReleaseItem.approvedStatus = approvedStatus;
        }
        state.status = "succeeded";
      });
  },
});

export const {
  setFilterPressRelease,
  setPage,
  setSearchQuery,
  deletePressReleaseItem,
  updatePressReleaseVisibility,
} = pressReleaseSlice.actions;

export default pressReleaseSlice.reducer;
