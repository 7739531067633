import React from "react";
import { RiSendPlaneFill } from "react-icons/ri";
import { Link } from "react-router-dom";

function UserLeftMenu() {
  return (
    <div className="left-menu">
      <div className="menu-item active">
        <Link to="/dashboard" className="text-decoration-none">
          <i className="fas fa-home" /> <span>My Press Release</span>
        </Link>
      </div>
      <div className="menu-item" onclick="setActive(this, 'search')">
        <i className="fas fa-search" /> <span>Billings</span>
      </div>
      {/* <div className="menu-item" onclick="setActive(this, 'notifications')">
        <i className="fas fa-bell" /> <span>Notifications</span>
      </div> */}
      <div className="menu-item" onclick="setActive(this, 'profile')">
        <i className="fas fa-user" /> <span>Settings</span>
      </div>
      <div className="menu-item">
        <p className="menu-button">
          <Link
            to="/dashboard/submit-press-release"
            className="text-decoration-none"
          >
            <RiSendPlaneFill size={16} className="me-1" />
            Submit Free Press Release
          </Link>
        </p>
      </div>
    </div>
  );
}

export default UserLeftMenu;
