import React, { useState, useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CForm,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CRow,
  CCol,
  CFormFeedback,
  CBadge,
  CFormTextarea,
} from "@coreui/react";
import { toast, ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import EditorInput from "../../../utils/admin/EditorInput";

const getAuthorFromLocalStorage = () => {
  const author = localStorage.getItem("author");
  return author
    ? JSON.parse(author)
    : { name: "Default Author", imgurl: "/path/to/default-image.jpg" };
};

const CreatePage = () => {
  const { url } = useParams();
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [customPage, setcustomPage] = useState("");
  const [inputValue, setInputValue] = useState("");

  const validationSchema = Yup.object({
    heading: Yup.string().required("Heading is required"),
    url: Yup.string().required("Path is required"),
    content: Yup.string().required("Content is required"),
  });

  const formik = useFormik({
    initialValues: {
      posterImg: null,
      heading: "",
      url: "",
      content: "",
      metaTitle: "",
      metaDescription: "",
      metaKeyWord: [],
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        console.log(values);

        if (isEditing) {
          await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/admin/customPage/edit-page/${url}`,
            values,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          toast.success("Post updated successfully!");
        } else {
          const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/admin/customPage/create-page`,
            values,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          toast.success("Post created successfully!");
          console.log(res?.data);
          formik.resetForm();
        }
        navigate(`/admin/pages/manage-pages`);
      } catch (error) {
        toast.error("Failed to post/update post.");
      }
    },
  });

  useEffect(() => {
    if (url) {
      setIsEditing(true);

      const fetchPost = async () => {
        try {
          const {data} = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/admin/customPage/get/${url}`
          );

          console.log(data);

          setcustomPage(data.page);

          setImagePreview(data.page.posterImg ? data.page.posterImg.url : null);
          formik.setValues({
            ...data.page,
            author: getAuthorFromLocalStorage(),
          });
        } catch (error) {
          toast.error("Failed to fetch post.");
        }
      };
      fetchPost();
    } else {
      setIsEditing(false);
      setImagePreview(null);
      formik.resetForm();
    }
  }, [url]);

  useEffect(() => {
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  const handleKeyWordRemove = (keyWord) => {
    const updatedkeyWord = formik.values.metaKeyWord.filter(
      (k) => k !== keyWord
    );
    formik.setFieldValue("metaKeyWord", updatedkeyWord);
  };

  const handleTagInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value.includes(",")) {
      const newKeyWord = value
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => tag && !formik.values.metaKeyWord.includes(tag));
   
      formik.setFieldValue("metaKeyWord", [...formik.values.metaKeyWord, ...newKeyWord]);
      setInputValue("");
    }
  };


  const handleChange = (e) => {
    const { name, type, value, files } = e.target;

    if (type === "file") {
      const file = files[0];
      if (file) {
        formik.setFieldValue(name, file);
        const previewUrl = URL.createObjectURL(file);
        setImagePreview(previewUrl);
      }
    } else {
      formik.setFieldValue(name, value);
    }
  };

  return (
    <>
      <ToastContainer />
      <CRow className="justify-content-center mb-3">
        <CCol md={12}>
          <CCard>
            <CCardHeader>
              <h3>{isEditing ? "Edit Post" : "Add Post"}</h3>
            </CCardHeader>
            <CCardBody>
              <CForm onSubmit={formik.handleSubmit}>
                <CRow className="mb-3">
                  <CCol md={12}>
                    <CFormLabel htmlFor="heading">Heading</CFormLabel>
                    <CFormInput
                      type="text"
                      id="heading"
                      name="heading"
                      value={formik.values.heading}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.touched.heading && formik.errors.heading
                      }
                      required
                      style={{ width: "100%" }}
                    />
                    <CFormFeedback style={{ color: "red" }}>
                      {formik.errors.heading}
                    </CFormFeedback>
                  </CCol>
                </CRow>

                <CRow className="mb-3">
                  <CCol md={6}>
                    <CFormLabel htmlFor="posterImg">Poster Image</CFormLabel>
                    {imagePreview && (
                      <div className="mb-3">
                        <img
                          src={imagePreview}
                          alt="Preview"
                          width="300px"
                          height="100%"
                        />
                      </div>
                    )}
                    {formik.touched.posterImg &&
                      formik.errors.posterImg &&
                      !isEditing && (
                        <CFormFeedback style={{ color: "red" }}>
                          {formik.errors.posterImg}
                        </CFormFeedback>
                      )}
                    <CInputGroup>
                      <CFormInput
                        type="file"
                        id="posterImg"
                        name="posterImg"
                        onChange={handleChange}
                        accept="image/*"
                        required={!isEditing}
                      />
                    </CInputGroup>
                  </CCol>
                </CRow>

                <CRow className="mb-3">
                  <CCol md={12}>
                    <CFormLabel htmlFor="url">Url Path</CFormLabel>
                    <CFormInput
                      type="text"
                      id="url"
                      name="url"
                      value={formik.values.url}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.url && formik.errors.url}
                      required
                      style={{ width: "100%" }}
                    />
                    <CFormFeedback style={{ color: "red" }}>
                      {formik.errors.url}
                    </CFormFeedback>
                  </CCol>
                </CRow>

                <CRow className="mb-3">
                  <CCol md={12}>
                    <CFormLabel htmlFor="content">Full Content</CFormLabel>
                    <EditorInput
                      id="content"
                      name="content"
                      value={formik.values.content}
                      onChange={(value) =>
                        formik.setFieldValue("content", value)
                      }
                    />
                    <CFormFeedback style={{ color: "red" }}>
                      {formik.errors.content}
                    </CFormFeedback>
                  </CCol>
                </CRow>

                <CRow className="mb-3">
                  <CCol md={12}>
                    <CFormLabel htmlFor="heading">Meta Title</CFormLabel>
                    <CFormInput
                      type="text"
                      id="metaTitle"
                      name="metaTitle"
                      value={formik.values.metaTitle}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.touched.metaTitle && formik.errors.metaTitle
                      }
                      required
                      style={{ width: "100%" }}
                    />
                    <CFormFeedback style={{ color: "red" }}>
                      {formik.errors.metaTitle}
                    </CFormFeedback>
                  </CCol>
                </CRow>

                <CRow className="mb-3">
                  <CCol md={12}>
                    <CFormLabel htmlFor="heading">Meta Description</CFormLabel>
                    <CFormTextarea
                      id="metaDescription"
                      name="metaDescription"
                      value={formik.values.metaDescription}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.touched.metaDescription && formik.errors.metaDescription
                      }
                      required
                      style={{ width: "100%" }}
                    />
                    <CFormFeedback style={{ color: "red" }}>
                      {formik.errors.metaDescription}
                    </CFormFeedback>
                  </CCol>
                </CRow>

                <CRow className="mb-3">
                  <CCol md={12}>
                    <CFormLabel htmlFor="tags"> Meta KeyWord</CFormLabel>
                    <CInputGroup>
                      <CFormInput
                        type="text"
                        id="metaKeyWord"
                        name="metaKeyWord"
                        value={inputValue}
                        onChange={handleTagInputChange}
                        placeholder="Add keyWords separated by commas"
                        style={{ width: "100%" }}
                      />
                    </CInputGroup>
                    <div className="mt-2">
                      {formik.values.metaKeyWord.map((keyWord) => (
                        <CBadge
                          color="info"
                          className="me-2 mb-2"
                          key={keyWord}
                          onClick={() => handleKeyWordRemove(keyWord)}
                          style={{ cursor: "pointer" }}
                        >
                          {keyWord}
                        </CBadge>
                      ))}
                    </div>
                  </CCol>
                </CRow>
                <CRow className="mb-3">
                  <CCol md={12} className="d-flex justify-content-end">
                    <CButton
                      type="submit"
                      color="primary"
                      className="me-2"
                      disabled={formik.isSubmitting}
                    >
                      {isEditing ? "Update Page" : "Create Page"}
                    </CButton>
                    {/* <CButton
                      type="button"
                      color="secondary"
                      onClick={() =>
                        navigate(
                          isEditing
                            ? `/admin/press-release/${singlePressReleaseData?.approvedStatus}`
                            : `/admin/press-release/approved`
                        )
                      }
                    >
                      Cancel
                    </CButton> */}
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default CreatePage;
