import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Table, Button, Modal } from "react-bootstrap";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineDeleteForever } from "react-icons/md";
import {
  CCard,
  CCardHeader,
  CContainer,
  CTable,
  CTableDataCell,
  CTableHead,
  CTableRow,
} from "@coreui/react";
import { Link, useNavigate } from "react-router-dom";

const ManagePages = () => {
  const [pages, setPages] = useState([]);
const navigate=useNavigate();

  useEffect(() => {
    fetchPages();
  }, []);

  // Fetch pages
  const fetchPages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/customPage/get-all`
      );
      setPages(response.data.pages);
    } catch (error) {
      console.error("Error fetching pages", error);
    }
  };

  // Edit Page
  const handleEdit = (page) => {
    navigate(`/admin/pages/edit/${page.url}`)
    
  };

  // Delete Page
  const handleDelete = async (url) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/admin/customPage/delete/${url}`
      );
      fetchPages();
    } catch (error) {
      console.error("Error deleting page", error);
    }
  };

  return (
    <CContainer>
      <CCard className="p-1">
        <CCardHeader>
          <h3>Page List</h3>
        </CCardHeader>

        {/* Page Table */}
        <CTable align="middle" className="mb-0 border" hover responsive>
          <CTableHead>
            <CTableRow>
              <CTableDataCell>#</CTableDataCell>
              <CTableDataCell>Page Name/Heading</CTableDataCell>
              <CTableDataCell>Url</CTableDataCell>
              <CTableDataCell>Actions</CTableDataCell>
            </CTableRow>
          </CTableHead>
          <tbody>
            {pages.map((page, index) => (
              <CTableRow key={page._id}>
                <CTableDataCell>{index + 1}</CTableDataCell>
                <CTableDataCell>{page.heading}</CTableDataCell>
                <CTableDataCell><Link to={`/pr/${page.url}`} target="_blank" className="text-decoration-none text-reset">/pr/{page.url}</Link></CTableDataCell>
                <CTableDataCell>
                  <div className="d-flex">
                    <FaRegEdit
                      className="me-3"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleEdit(page)}
                    />
                    <MdOutlineDeleteForever
                      style={{ cursor: "pointer", color: "red" }}
                      onClick={() => handleDelete(page.url)}
                    />
                  </div>
                </CTableDataCell>
              </CTableRow>
            ))}
          </tbody>
        </CTable>
      </CCard>
    </CContainer>
  );
};

export default ManagePages;
