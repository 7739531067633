import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve({ default: reader.result });
      };
      reader.onerror = reject;
      this.loader.file.then((file) => {
        reader.readAsDataURL(file);
      });
    });
  }

  abort() {}
}

function EditorInput({ value, onChange, onBlur, inputKey, name }) {
  const editorConfiguration = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "fontSize",
        "fontFamily",
        "|",
        "bold",
        "italic",
        "link",
        "uploadImage",
        "bulletedList",
        "numberedList",
        "|",
        "codeBlock",
        "blockQuote",
        "|",
        "insertTable",
        "tableColumn",
        "tableRow",
        "mergeTableCells",
      ],
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    fontSize: {
      options: [
        9,
        11,
        13,
        "default",
        17,
        19,
        21,
        25,
        30,
        {
          title: "Tiny",
          model: "9px",
          view: "span",
          class: "text-tiny",
        },
        {
          title: "Small",
          model: "13px",
          view: "span",
          class: "text-small",
        },
        {
          title: "Big",
          model: "25px",
          view: "span",
          class: "text-big",
        },
        {
          title: "Large",
          model: "30px",
          view: "span",
          class: "text-large",
        },
      ],
    },
    fontFamily: {
      options: [
        "default",
        "Arial, Helvetica, sans-serif",
        "Courier New, Courier, monospace",
        "Georgia, serif",
        "Lucida Sans Unicode, Lucida Grande, sans-serif",
        "Tahoma, Geneva, sans-serif",
        "Times New Roman, Times, serif",
        "Trebuchet MS, Helvetica, sans-serif",
        "Verdana, Geneva, sans-serif",
      ],
      supportAllValues: true,
    },
    typing: {
      transformations: {
        remove: [
          // Do not transform to bold or italic
          "symbols/trademark",
          "symbols/registered",
          "symbols/copyright",
        ],
      },
    },
  };

  const createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };

  return (
    <div className="editor-container w-full">
      <CKEditor
        key={inputKey}
        editor={ClassicEditor}
        name={name}
        data={value || ""}
        config={editorConfiguration}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(data);
        }}
        onBlur={onBlur}
        onReady={(editor) => {
          editor.plugins.get("FileRepository").createUploadAdapter = (
            loader
          ) => {
            return createUploadAdapter(loader);
          };
        }}
      />
      <style jsx global>{`
        .editor-container {
          position: relative;
          color: #333 !important;
        }

        .ck.ck-editor__editable:not(.ck-editor__nested-editable) {
          min-height: 450px;
          color: #000 !important;
        }

        .ck.ck-content {
          color: #000 !important;
          font-size: 1rem;
          line-height: 1.5;
        }

        .ck.ck-content,
        .ck.ck-content * {
          color: #333 !important;
        }

        /* Additional styles for font sizes */
        .text-tiny {
          font-size: 9px !important;
        }
        .text-small {
          font-size: 13px !important;
        }
        .text-big {
          font-size: 25px !important;
        }
        .text-large {
          font-size: 30px !important;
        }
      `}</style>
    </div>
  );
}

export default EditorInput;
