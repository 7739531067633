import { configureStore } from "@reduxjs/toolkit";
import flashSlice from "./client/flashSlice";
import authSlice from "./client/authSlice";
import adminAppSlice from "./admin/adminAppSlice";
import adminAuthSlice from "./admin/adminAuthSlice";
import pressReleaseSlice from "./admin/adminPressReleaseSlice";
import adsSlice from "./AdsSlice";
import notificationReducer from "./notificationSlice.js";
import recomendedPressReleaseSlice from "./client/recomendedPressReleaseSlice.js";
import pressReleaseFetchSlice from "./client/pressReleaseFetchSlice.js";

const pressReleaseStore = configureStore({
  reducer: {
    flashMessages: flashSlice.reducer,
    auth: authSlice.reducer,
    adminApp: adminAppSlice,
    adminAuth: adminAuthSlice,
    adminPressRelease: pressReleaseSlice,
    pressRelease: pressReleaseFetchSlice.reducer,
    recomendedPressRelease: recomendedPressReleaseSlice.reducer,
    ads: adsSlice,
    notification: notificationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        warnAfter: 128,
      },
    }),
});

export default pressReleaseStore;
