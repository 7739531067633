import { createSlice } from "@reduxjs/toolkit";

const flashSlice = createSlice({
  name: "flash",
  initialState: { message: "", type: "success" },
  reducers: {
    addFlash: (state, action) => {
      return { message: action.payload.message, type: action.payload.type };
    },
    clearFlash: () => {
      return { type: "success" };
    },
  },
});
export const flashAction = flashSlice.actions;
export default flashSlice;
