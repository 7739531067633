import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchRecomendedPressRelease = createAsyncThunk(
  "pressRelease/fetchRecomended",
  async ({ limit, restricted, category }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/press-release/getRecomemndedPressRelease?limit=${limit}&restricted=${restricted}&category=${category}`
    );

    return response.data;
  }
);

const recomendedPressReleaseSlice = createSlice({
  name: "recomendedPressRelease",
  initialState: {
    pressReleaseList: [],
    recomndedPressReleaseStatus: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecomendedPressRelease.pending, (state) => {
        state.recomndedPressReleaseStatus = "loading";
      })
      .addCase(fetchRecomendedPressRelease.fulfilled, (state, action) => {
        state.recomndedPressReleaseStatus = "succeeded";
        if (
          state.pressReleaseList.length === 0 ||
          action.payload.type === state.pressReleaseList[0].type
        ) {
          action.payload.pressRelease.forEach((newItem) => {
            if (
              !state.pressReleaseList.some(
                (existingItem) => existingItem.slug === newItem.slug
              )
            ) {
              state.pressReleaseList.push(newItem);
            }
          });
        } else {
          state.pressReleaseList = action.payload.pressRelease;
        }
        state.total = action.payload.total;
        state.page = action.payload.page;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchRecomendedPressRelease.rejected, (state, action) => {
        state.recomndedPressReleaseStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const SelectRecomendedPressRelease = (state) =>
  state.recomendedPressRelease;
export const selectRecomendedPressReleaseList = (state) =>
  state.recomendedPressRelease.pressReleaseList;

export default recomendedPressReleaseSlice;
