import React, { useCallback, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { GoSignOut } from "react-icons/go";
import { FaRegUserCircle } from "react-icons/fa";
import style from "../../css/TopBar.module.css";
import { signout } from "../../store/client/authSlice";

const TopBar = () => {
  const dispatch = useDispatch();
  const isSignin = useSelector((state) => state.auth.isSignin);
  const location = useLocation();

  const [width, setWidth] = useState(window.innerWidth);
  const lastPathname = useRef(location.pathname);

  const handleResize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const handleResizeEvent = () => handleResize();
    window.addEventListener("resize", handleResizeEvent);
    return () => window.removeEventListener("resize", handleResizeEvent);
  }, [handleResize, isSignin, dispatch]);

  useEffect(() => {
    const currentPath = location.pathname;
    if (
      (lastPathname.current === "/signin" && currentPath === "/signup") ||
      (lastPathname.current === "/signup" && currentPath === "/signin")
    ) {
      window.location.reload();
    }
    lastPathname.current = currentPath;
  }, [location.pathname]);

  const handleSignout = () => {
    dispatch(signout());
    localStorage.removeItem("token");
  };

  return (
    <Container fluid className={`${style.topbar} text-dark py-2`}>
      <Row>
        <Col className="d-flex justify-content-end p-0">
          <div className="d-flex align-items-center">
            {!isSignin ? (
              <>
                <Link to="/signin" className="mx-2">
                  <button className="buttonClassPrimary">Login</button>
                </Link>
                <Link to="/signup">
                  <button className="buttonClassPrimary">Register</button>
                </Link>
              </>
            ) : (
              <>
                <Link to="/dashboard" className="mx-2">
                  <button className="buttonClassPrimary">Dashboard</button>
                </Link>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSignout();
                  }}
                >
                  <button className="buttonClassPrimary">Logout</button>
                </Link>
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TopBar;
