import React from 'react';

const ModernLoader = ({ size = 100, color = '#3b82f6', speed = 1.5 }) => {
  const loaderStyle = {
    display: 'inline-block',
    width: `${size}px`,
    height: `${size}px`,
    border: `${Math.max(2, size / 10)}px solid ${color}`,
    borderRightColor: 'transparent',
    borderRadius: '50%',
    animation: `spin ${speed}s linear infinite`,
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '20px',
  };

  const keyframes = `
    @keyframes spin {
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg); }
    }
  `;

  return (
    <>
      <style>{keyframes}</style>
      <div style={containerStyle}>
        <div style={loaderStyle} aria-label="Loading"></div>
      </div>
    </>
  );
};

export default ModernLoader;