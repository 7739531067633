import React, { useEffect, useState } from "react";
import TopBar from "./TopBar";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { flashAction } from "../../store/client/flashSlice";
import { fetchCategories } from "../../utils/common/Categories";
import { Navbar } from "react-bootstrap";

const Header = () => {
  const [isMobileNavActive, setMobileNavActive] = useState(false);
 


  
  return (
    <header
      id="header"
      className={`header  ${isMobileNavActive ? "mobile-nav-active" : ""}`}
    >
      <div className="topbar d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            <i className="bi bi-envelope d-flex align-items-center">
              <Link to="mailto:pr@pressrelease.org">pr@pressrelease.org</Link>
            </i>
            <i className="bi bi-phone d-flex align-items-center ms-4">
              <Link to="tel:+919810359349">+91.9810359349</Link>
            </i>
          </div>
          <div className="social-links d-none d-md-flex align-items-center">
            <Link
              to="https://x.com/PressReleaseMKT"
              target="_blank"
              rel="noopener noreferrer"
              className="twitter"
            >
              <i className="bi bi-twitter-x"></i>
            </Link>
            <Link
              to="https://www.facebook.com/submitfreepressrelease"
              target="_blank"
              rel="noopener noreferrer"
              className="facebook"
            >
              <i className="bi bi-facebook"></i>
            </Link>
            <Link
              to="#"
              target="_blank"
              rel="noopener noreferrer"
              className="linkedin"
            >
              <i className="bi bi-linkedin"></i>
            </Link>
          </div>
        </div>
      </div>

      <div className="branding d-flex align-items-center">
        <div className="container position-relative d-flex align-items-center justify-content-between">
          <div>
            <Link to="/" className="logo d-flex align-items-center me-auto">
              <img
                src="/images/weblogo-pr.png"
                alt="Press Release"
                height="100%"
                width="100%"
              />
            </Link>
          </div>

          <div className="d-flex">
            {/* <div>
              <nav id="navmenu" className="navmenu">
                <ul>
                  <li>
                    <Link
                      to="/"
                      className="active"
                      onClick={handleNavLinkClick}
                    >
                      Home
                      <br />
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" onClick={handleNavLinkClick}>
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/pricing" onClick={handleNavLinkClick}>
                      Pricing
                    </Link>
                  </li>
                  <li className={`dropdown ${isDropdownOpen ? "open" : ""}`}>
                    <Link to="/" onClick={handleDropdownToggle}>
                      <span>Industries</span>{" "}
                      <i className="bi bi-chevron-down toggle-dropdown"></i>
                    </Link>
                    {isDropdownOpen && (
                      <ul>
                        {categories.map((industry, index) => (
                          <li key={index}>
                            <Link
                              to={`/${industry.name
                                .toLowerCase()
                                .replace(/\s+/g, "-")}-press-release`}
                              onClick={handleCategoryClick}
                              className="text-capitalize"
                            >
                              {industry?.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>

                  <li>
                    <Link
                      to="/dashboard/submit-press-release"
                      onClick={handlePressReleaseClick}
                    >
                      Submit Free PR
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" onClick={handleNavLinkClick}>
                      Contact
                    </Link>
                  </li>
                </ul>

                <div className="d-block d-md-none  ">
                  <i
                    className={`mobile-nav-toggle d-xl-none bi ${
                      isMobileNavActive ? "bi-x" : "bi-list"
                    }`}
                    onClick={toggleMobileNav}
                  />
                </div>
              </nav>
            </div> */}

            <div className="">
              <TopBar />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
