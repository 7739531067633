import React, { Suspense, lazy, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { ToastContainer } from "react-toastify";
import DefaultTitle from "../utils/common/DefaultTitle";
import ScrollToTop from "../utils/common/ScrollToTop";
import Header from "../component/client/Header";
import Footer from "./Footer";
import "aos/dist/aos.css";
import Aos from "aos";
import useNotifications from "../utils/client/useNotifications";
import { useSelector } from "react-redux";
import Shimmer from "../component/shimmers/Shimmer";
import Navbar from "../component/client/Navbar";
import NavbarTest from "../component/client/NavbarTest";
import ScrollToTopIcon from "../component/client/ScrollToTopIcon";

function App() {
  const location = useLocation();
  const loginUser = useSelector((state) => state.auth.user);
  const generateTitle = (path) => {
    if (path === "/") {
      return "press release";
    }

    const segments = path.split("/").filter(Boolean);

    if (segments.length > 0) {
      const lastSegment = segments[segments.length - 1];
      return lastSegment;
    }

    return "Default Title";
  };

  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, []);
  const title = generateTitle(location.pathname);
  useNotifications(loginUser?._id);

  return (
    <Suspense
      fallback={<Shimmer width="100rem" height="100%" borderRadius="1rem" />}
    >
      <ScrollToTop />
      <div>
        <DefaultTitle title={title} />
        <Header />
        <NavbarTest />
        <main>
          <Outlet />
        </main>
        <ScrollToTopIcon />
        <Footer />
      </div>

      <ToastContainer />
    </Suspense>
  );
}

export default App;
