import React from "react";

const ViewIcon = ({ className, ...props }) => (
  <svg
    aria-hidden="true"
    className={`r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-1xvli5t r-1hdv0qi ${className}`}
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M8.75 21V3h2v18zM18 21V8.5h2V21zM4 21l.004-10h2L6 21zm9.248 0v-7h2v7z"></path>
  </svg>
);

export default ViewIcon;
