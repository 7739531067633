import React, { useEffect, useState } from "react";
import PressReleaseCardStyles from "../../css/PressReleaseCard.module.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Row } from "react-bootstrap";
import PressReleaseCard from "./PressReleaseCard";
import DynamicBreadcrumb from "./BreadCrumb";

function HashTag() {
  const { tag } = useParams();

  const [pressReleaseList, setPressReleaseList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (tag) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/tags/get/${tag}`)
        .then((response) => {
          setPressReleaseList(response.data.pressRelease);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setError("Failed to fetch Press Release for this hashtag.");
          setLoading(false);
        });
    } else {
      setError("Hashtag not found in the URL.");
      setLoading(false);
    }
  }, [tag]);

  return (
    <>
      <div className=" container">
        <Row className="  mt-4">
          <DynamicBreadcrumb />
        </Row>
        <h1 className="text-capitalize mt-1 mb-4 pb-1 ps-0 press-category-heading">
          {tag} Press Release
        </h1>
        {pressReleaseList?.map((pressReleaseItem) => (
          <PressReleaseCard
            key={pressReleaseItem?.id}
            pressRelease={pressReleaseItem}
            styles={PressReleaseCardStyles}
          />
        ))}
      </div>
    </>
  );
}

export default HashTag;
