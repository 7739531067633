import axios from "axios";

export const fetchCategories = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/admin/getCategory`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching categories", error);
    return [];
  }
};






export const industryData = [
  {
    iconClass: "fa-solid fa-palette",
    title: "Art & Entertainment",
    delay: 100,
  },
  { iconClass: "fa-solid fa-car", title: "Automotive", delay: 200 },
  {
    iconClass: "fa-brands fa-youtube",
    title: "Blogging & Social Media",
    delay: 300,
  },
  { iconClass: "fa-solid fa-business-time", title: "Business", delay: 400 },
  { iconClass: "fa-solid fa-display", title: "Computer", delay: 500 },
  { iconClass: "fa-solid fa-pen", title: "Design", delay: 600 },
  { iconClass: "fa-solid fa-book-open", title: "Education", delay: 100 },
  { iconClass: "fa-solid fa-leaf", title: "Environment", delay: 200 },
  {
    iconClass: "fa-solid fa-money-check-dollar",
    title: "Events & Trade Shows",
    delay: 300,
  },
  {
    iconClass: "fa-solid fa-gamepad",
    title: "Games & Entertainment",
    delay: 400,
  },
  { iconClass: "fa-solid fa-building-ngo", title: "Government", delay: 500 },
  {
    iconClass: "fas fa-notes-medical",
    title: "Health and Fitness",
    delay: 600,
  },
  { iconClass: "fa-solid fa-house", title: "Home & Family", delay: 100 },
  { iconClass: "fa-solid fa-industry", title: "Industry", delay: 100 },
  { iconClass: "fa-solid fa-gavel", title: "Legal & Law", delay: 100 },
  { iconClass: "fas fa-heartbeat", title: "Lifestyle", delay: 100 },
  { iconClass: "fa-solid fa-landmark", title: "News & Society", delay: 100 },
  { iconClass: "fa-solid fa-handshake", title: "Politics", delay: 100 },
  { iconClass: "fa-solid fa-person-praying", title: "Religion", delay: 100 },
  { iconClass: "fa-solid fa-baseball-bat-ball", title: "Sports", delay: 100 },
  { iconClass: "fa-solid fa-sim-card", title: "Technology", delay: 100 },
  { iconClass: "fa-solid fa-wifi", title: "Websites & Internet", delay: 100 },
];