import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { fetchCategories } from "../../utils/common/Categories";
import { flashAction } from "../../store/client/flashSlice";
import { RiSendPlaneFill } from "react-icons/ri";
import {Col, Row} from 'react-bootstrap'

function NavbarTest() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSignin = useSelector((state) => state.auth.isSignin);
  const [categories, setCategories] = useState([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMobileNavActive, setMobileNavActive] = useState(false);

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    const data = await fetchCategories();
    setCategories(data);
  };

  const toggleMobileNav = () => {
    setMobileNavActive(!isMobileNavActive);
  };

  const handleNavLinkClick = () => {
    if (isMobileNavActive) {
      setMobileNavActive(false);
    }
  };

  const handleDropdownToggle = (e) => {
    e.preventDefault();
    setDropdownOpen(!isDropdownOpen);
  };
  const handleCategoryClick = () => {
    setDropdownOpen(false);
    setMobileNavActive(false);
  };

  const handlePressReleaseClick = (e) => {
    if (isMobileNavActive) {
      setMobileNavActive(false);
    }

    if (!isSignin) {
      e.preventDefault();
      dispatch(
        flashAction.addFlash({
          message: "Please log in to access this page.",
          type: "Fail",
        })
      );
      navigate("/signin");
    } else {
      navigate("/dashboard/submit-press-release");
    }
  };

  return (
    <header
      id="header"
      className={`header bgRedHeader ${
        isMobileNavActive ? "mobile-nav-active" : ""
      }`}
    >
      <div className="pd-nav container d-flex">
        <nav id="navmenu" className="navmenu">
          <ul>
            <li>
              <Link to="/" className="active" onClick={handleNavLinkClick}>
                Home
                <br />
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={handleNavLinkClick}>
                About
              </Link>
            </li>
            <li>
              <Link to="/pricing" onClick={handleNavLinkClick}>
                Pricing
              </Link>
            </li>
            <li
              className={`dropdown ${isDropdownOpen ? "open" : ""}`}
              onMouseEnter={handleDropdownToggle}
              onMouseLeave={handleDropdownToggle}
            >
              <Link to="/">
                <span>Industries</span>{" "}
                <i className="bi bi-chevron-down toggle-dropdown"></i>
              </Link>
              {isDropdownOpen && (
                <>
                  <div className="dropdown-content">
                    <Row>
                      {categories.map((industry, index) => (
                        <Col
                          xs={12}
                          lg={6}
                          key={index}
                          className="dropdownItem"
                        >
                          <Link
                            to={`/${industry.name
                              .toLowerCase()
                              .replace(/\s+/g, "-")}-press-release`}
                            onClick={handleCategoryClick}
                            className="text-capitalize"
                          >
                            {industry?.name}
                          </Link>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </>
              )}
            </li>
            <li>
              <Link to="/contact" onClick={handleNavLinkClick}>
                Contact
              </Link>
            </li>
          </ul>

          <div className="d-block d-md-none  ">
            <i
              className={`mobile-nav-toggle d-xl-none bi ${
                isMobileNavActive ? "bi-x" : "bi-list"
              }`}
              onClick={toggleMobileNav}
            />
          </div>
        </nav>

        <div className="freePR">
          <Link
            to="/dashboard/submit-press-release"
            onClick={handlePressReleaseClick}
          >
            {" "}
            <RiSendPlaneFill size={16} className="me-1" />
            Submit Free Press Release
          </Link>
        </div>
      </div>
    </header>
  );
}

export default NavbarTest;
